import React from "react"
import Layout from "../components/layout"
import {
  HeaderSecondary,
  ContactSection,
  DecorationSection,
  MobilitySection,
} from "../components/utility"

import { useStaticQuery, graphql } from "gatsby"

export default function Mobility() {
  const data = useStaticQuery(graphql`
    query {
      brand1: file(relativePath: { eq: "product/mobilityProduct.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const Product1 = [
    data.brand1.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35))`,
  ].reverse()

  return (
    <Layout>
      <HeaderSecondary
        fluid={Product1}
        title="Simpler, Smarter Mobile Plans"
        caption="More Data – More Value – More to Share"
      />
      <MobilitySection />
      <ContactSection />
      <DecorationSection />
    </Layout>
  )
}
